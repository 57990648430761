import * as React from "react";
import Layout from "../components/layouts/Layout";
import HospitalComponent from "../components/Hospital/Hospital";
import hospitalBackground from "../assets/img/backgroundSections/hospitalSection.webp";

const Hospital = () => {
  return (
    <Layout background={hospitalBackground} effects={'blur-sm'}>
      <HospitalComponent />
    </Layout>
  )
}

export default Hospital;