import React, { useContext, useState, useEffect } from 'react';
//import { Link } from 'gatsby';
//import WaifuCard from '../elements/WaifuCardItem';
//import dialogWaifu from "../../assets/img/dialogWaifu2.svg";
import waifuMintIcon from "../../assets/vector/waifuMintIcons/waifuMintIcon.svg";
import UserContext from "../../context/userContext/UserContext";
//import princessWaifu from "../../assets/img/hospitalSection/princessWaifu.webp";
import Card from '../elements/maintenance/sickWaifuCard';
import { Swiper, SwiperSlide } from "swiper/react";
import contractAddress from "../../utils/Contract.json"
import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg";
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg";
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg";
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg";
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg";
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg";
import Loading from '../Loading/Loading';
import Loader from '../../components/elements/ModalX/loader';

import HelpHospital from '../../helpers/hospital';

import peachIcon from "../../assets/img/pechicon2.svg";

export default function Hospital() {

    const { contract, account, chainId } = useContext(UserContext);
    const [waifusSick, setWaifusSick] = useState(null)
    const [arrayIcon] = useState([cottonIcon, woolIcon, leatherIcon, leaveIcon, flaxIcon, silkIcon])
    const [allowance, setAllowance] = useState(false)
    const [loading, setLoading] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [peachPrice, setPricePeach] = useState(0);

    useEffect(() => {
        if (contract && account) {

            contract.contractWaifu.methods.getFarmerIdsOf(account).call().then(result => {
                const getAllWaifus = []
                result.map(element => { if (element !== "1000000000000000000") return getAllWaifus.push(contract.contractWaifu.methods.getFarmer(element).call()) })
                Promise.all(getAllWaifus).then(result => {
                    const listAllWaifus = []
                    result.reverse().map(element => {

                        let timeNow = new Date()
                        let sickLeft = element.sickUntil - (timeNow.getTime() / 1000)
                        if (sickLeft > 0) listAllWaifus.push({ adn: '0'.repeat(61 - element.dna.length) + element.dna, ...element, waifuPower: Math.max(...element.waifuPowers) })

                    })
                    setWaifusSick(listAllWaifus)

                }).catch(err => setWaifusSick([]))
            });

            contract.contractPeach.methods.getCurrentPrice().call({ from: account }).then((result) => {
                setPricePeach(1000);
            }).catch(err => console.error(err));
        }
    }, [contract, account, updateData]);

    useEffect(() => {
        if (contract) contract.contractPeach.methods.allowance(account, process.env.GATSBY_DWGAME).call().then(result => parseInt(result) > 0 && setAllowance(true)).catch(err => console.error(err))
    }, [contract])

    useEffect(() => {

        if (document) {

            let documentTest = document.getElementsByClassName("swiper-wrapper")
            for (var i = 0; i < documentTest.length; i++) {
                documentTest[i].className += " flex";
            }

        }


    }, [document])


    const healWaifu = (element) => {

        return new Promise((resolve, reject) => {

            if (contract) {
                setLoading(true)

                if (!allowance) {
                    contract.contractPeach.methods.approve(process.env.GATSBY_DWGAME, 5 + '0'.repeat(24)).send({ from: account }).then(result => {
                        contract.contractDWGameFarmer.methods.heal(element.id).send({ from: account, value: 2 + '0'.repeat(15) }).then(result => { setUpdateData(!updateData); resolve(true); }).catch(err => reject(false));
                    }).catch(err => { console.error(err); reject(false); })
                }
                else contract.contractDWGameFarmer.methods.heal(element.id).send({ from: account, value: 2 + '0'.repeat(15) }).then(result => { setUpdateData(!updateData); resolve(true); }).catch(err => reject(false));

            }

        })

    }

    const multiHealWaifu = () => {
        const list_sick_waifu = HelpHospital.getSickWaifus(waifusSick);

        if (list_sick_waifu.length === 0) return;
        if (contract) {
            setLoading(true)

            if (!allowance) {
                contract.contractPeach.methods.approve(contractAddress.GATSBY_DWGAME, 5 + '0'.repeat(24)).send({ from: account }).then(result => {
                    contract.contractDWGameFarmer.methods.multiHeal(list_sick_waifu).send({ from: account, value: (2 * list_sick_waifu.length) + '0'.repeat(15) }).then(result => { setUpdateData(!updateData); setLoading(false); }).catch(err => setLoading(false));
                }).catch(err => { setLoading(false) })
            }
            else contract.contractDWGameFamer.methods.multiHeal(list_sick_waifu).send({ from: account, value: (2 * list_sick_waifu.length) + '0'.repeat(15) }).then(result => { setUpdateData(!updateData); setLoading(false); }).catch(err => setLoading(false));

        }
    }

    return (
        <div className="relative py-5">
            {!waifusSick ? <div className="h-80 flex justify-center items-center"><Loader /></div>

                : <> {waifusSick.length > 0
                    ? <div className="px-4">
                        <div className="flex justify-between mb-2 items-center flex-col md:flex-row">
                            <div className="text-darkPink text-2xl font-semibold hidden md:block mix-blend-color-burn">Sick Waifus</div>
                            <div className="text-darkPink border-darkPink border-2 rounded-lg bg-pink-200 bg-opacity-70 p-2 px-4 flex items-center h-12">
                                <div className="text-md md:text-lg font-semibold mr-2 flex items-center">
                                    <div>{HelpHospital.getMPriceWaifu(waifusSick, peachPrice)}</div>
                                    <img src={peachIcon} className="w-6 ml-2" />
                                </div>
                                <button className="text-white font-semibold md:font-bold rounded-full p-1 px-3 bg-darkPink" onClick={multiHealWaifu}>Heal All</button>
                            </div>
                        </div>
                        <div className="bg-primary overflow-x-auto overflow-y-hidden flex w-full p-4  m-auto mb-12  rounded-lg rounded-br-lg rounded-bl-lg   ">
                            <Swiper
                                breakpoints={{

                                    1250: { slidesPerView: 4 },
                                    900: { slidesPerView: 3 },
                                    600: { slidesPerView: 2 },
                                    200: { slidesPerView: 1 }

                                }}
                                spaceBetween={10}
                                pagination={{ clickable: true, }}
                                className="mySwiper"
                            >
                                {waifusSick?.map(element => <SwiperSlide> <Card setLoading={setLoading} healWaifu={healWaifu} icon={arrayIcon} damanger={"Sick"} element={element} peachPrice={peachPrice} />  </SwiperSlide>)}

                            </Swiper>

                        </div>
                    </div>


                    : <div className="grid grid-rows-mint justify-center text-center mt-16">
                        <img src={waifuMintIcon} alt="waifu havent mint" className="w-44 mx-auto self-center"></img>
                        <span className="font-bold text-base text-black mx-4">It seems that you don't have any sick waifu at this moment</span>

                    </div>


                }

                </>
            }

            <Loading modal={loading} setModal={setLoading} />


        </div >
    )
}


