import React from 'react';
import loadIcon from "../../assets/img/loadingIcon.webp";
import Modal from '../elements/Modal';
import Loader from '../elements/ModalX/loader';
export default function Loading({ modal, setModal }) {
    return (<Modal loadingDisable={true} screen={true} modal={modal} setModal={setModal}>
        <div className="p-10">
            <Loader />
        </div>
    </Modal>)
}
