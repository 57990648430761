const getSickWaifus = (waifusSick) => {
    const list_sick_waifu = waifusSick.filter(e => {
        let a = new Date().getTime();
        let b = e.sickUntil - (a / 1000);
        if (b > 43200) return true;
        return false;
    }).map(e => { return e.id });

    return list_sick_waifu;
}

const getMPriceWaifu = (waifusSick, peachPrice) => {
    const list_sick_waifu = waifusSick.filter(e => {
        let a = new Date().getTime();
        let b = e.sickUntil - (a / 1000);
        if (b > 43200) return true;
        return false;
    }).map(e => {
        let starWaifu = Math.max(...e.waifuPowers);
        if (parseInt(starWaifu) <= 49) return 1.60;
        else if (parseInt(starWaifu) <= 99) return 2.00;
        else if (parseInt(starWaifu) <= 149) return 2.40;
        else if (parseInt(starWaifu) <= 199) return 2.80;
        else if (parseInt(starWaifu) <= 250) return 3.40;
    });
    let prices = 0;
    for (let val of list_sick_waifu) {
        prices += val
    }

    return prices;
}

export default { getSickWaifus, getMPriceWaifu };