import React, { useEffect, useState } from "react";
import day from "../../../assets/img/waifuBackground/day.webp";
import afternoon from "../../../assets/img/waifuBackground/afternoon.webp";
import night from "../../../assets/img/waifuBackground/night.webp";
import dawn from "../../../assets/img/waifuBackground/dawn.webp";
import evening from "../../../assets/img/waifuBackground/evening.webp";
import Linda from "../../../assets/img/waifuImage/Linda.webp";
import Lulu from "../../../assets/img/waifuImage/Lulu.webp";
import Lumilia from "../../../assets/img/waifuImage/Lumilia.webp";
import Mai from "../../../assets/img/waifuImage/Mai.webp";
import Pinango from "../../../assets/img/waifuImage/Pinango.webp";
import Samantha from "../../../assets/img/waifuImage/Samantha.webp";
import capsule from "../../../assets/img/waifuBackground/capsule.webp";

import start1 from "../../../assets/vector/startWaifu1.svg"
import start2 from "../../../assets/vector/startWaifu2.svg"
import start3 from "../../../assets/vector/startWaifu3.svg"
import start4 from "../../../assets/vector/startWaifu4.svg"
import start5 from "../../../assets/vector/startWaifu5.svg"

import peachIcon from "../../../assets/img/pechicon2.svg";


import GetImageAdn from "../getImageAdn";

const WaifuCard = ({ setLoading, healWaifu, selectStartWaifu, icon, selectWaifu, damanger, inventory, harvesting, element, market, harvestingMaterial, peachPrice }) => {
    const [haverstCard, setHarvestCard] = useState(harvesting);
    const [waifuPower, setWaifuPower] = useState(0);
    const [waifuIcon, setWaifuIcon] = useState(null);
    const [sickTime, setSickTime] = useState(null);
    const [status, statusAction] = useState('Sick');
    const [starWaifu, setStarWaifu] = useState(0);

    const durability = {
        now: parseInt(((Date.now() / 1000) - element?.birthday) / 84600),
        complete: parseInt((element?.aliveUntil - element?.birthday) / 84600)
    }

    const getTimeLeft = (seconds) => {


        var numdays = Math.floor(seconds / 86400);
        var numhours = Math.floor((seconds % 86400) / 3600);
        var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);

        if (numdays >= 1) return numdays + "d " + numhours + "h "

        return numhours + "h " + numminutes + "m ";
    }

    useEffect(() => {
        let timeNow = new Date()
        let secondsLeft = element.sickUntil - (timeNow.getTime() / 1000)
        let intervalId;


        if (secondsLeft > 0 && damanger) {
            intervalId = setInterval(() => {

                if (secondsLeft > 0) {
                    secondsLeft = secondsLeft - 1;
                    setSickTime({ id: element.id, secondsLeft: secondsLeft, seconds: getTimeLeft(secondsLeft) })
                }

            }, 1000)
            setSickTime({ id: element.id, secondsLeft: secondsLeft, seconds: getTimeLeft(secondsLeft) })
        } else setSickTime(null)

        return () => clearInterval(intervalId)


    }, [element])

    useEffect(() => {
        if (element && element.waifuPowers) {

            if (harvestingMaterial || harvestingMaterial === 0) {
                setWaifuIcon(icon)
                return setWaifuPower(element.waifuPowers[harvestingMaterial]);
            }

            let maxWaifuPower = Math.max(...element.waifuPowers)
            let indexWaifuPower = element.waifuPowers.indexOf(maxWaifuPower.toString())

            setWaifuIcon(icon[indexWaifuPower])
            setWaifuPower(maxWaifuPower);
        }
    }, [element])

    useEffect(() => {
        if (harvesting) {
            setHarvestCard("SELECT")
            let findWaifu = selectWaifu.find(elementTwo => elementTwo === parseInt(element.id))
            if (findWaifu) setHarvestCard("READY")
        }
    }, [selectStartWaifu])

    const startArray = [start1, start2, start3, start4, start5];

    const startValue = () => {
        if (!element) return 0

        let value;

        if (harvestingMaterial || harvestingMaterial === 0) value = element.waifuPowers[harvestingMaterial]
        else value = Math.max(...element.waifuPowers);



        if (harvestingMaterial || harvestingMaterial === 0) value = element.waifuPowers[harvestingMaterial]

        if (value <= 49) return setStarWaifu(0)
        else if (value <= 99) return setStarWaifu(1)
        else if (value <= 149) return setStarWaifu(2)
        else if (value <= 199) return setStarWaifu(3)
        else if (value <= 250) return setStarWaifu(4)
    }

    const background_time = (() => {
        let hour = (new Date()).getHours();
        if (hour < 4) return night;
        if (hour < 8) return dawn;
        if (hour < 16) return day;
        if (hour < 18) return afternoon;
        if (hour < 20) return evening;
        if (hour <= 24) return night;
    })();

    const handleClickButton = async () => {
        healWaifu(element).then(() => { statusAction("Recovering"); setLoading(false) }).catch(() => { setLoading(false) })
    }

    const getPricePeach = () => {
        if (starWaifu === 0) return 1.5;
        if (starWaifu === 1) return 2;
        if (starWaifu === 2) return 2.5;
        if (starWaifu === 3) return 3;
        if (starWaifu === 4) return 4.5;
    }

    useEffect(() => {
        startValue();
    }, [])

    return (

        <>
            {status === "Recovering" || (sickTime?.secondsLeft < 43200)
                ? <div className="relative h-64 w-[220px]  mx-5  flex justify-center items-center">
                    {(sickTime && sickTime.id === element.id) && <div className="absolute bottom-16 p-1 border-2 ml-4 rounded-full border-[#AA2E74] bg-[#d686b4] z-[30]">Sick Time: {sickTime.seconds}</div>}

                    {damanger && <img src={capsule} alt="recovering" className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-[220px] z-[25]`} />}
                    <GetImageAdn genData={element?.adn || "0003000300300030030003000300030030003003000300300030030030303"} mask={false} />
                </div>
                : <div className={`relative mx-auto hover:shadow-2xl w-[220px] cursor-pointer ease-in-out duration-300 rounded-b-lg overflow-hidden`} style={{ backgroundImage: `url(${background_time})`, backgroundSize: 'cover', backgroundPosition: 'bottom', backgroundPositionY: `${(haverstCard || market) ? '-80px' : '-80px'}` }}>

                    {(sickTime && sickTime.id === element.id) && <div className="absolute top-2 left-2 p-1 border-2 rounded-full border-[#AA2E74] bg-[#d686b4]">Sick Time: {sickTime.seconds}</div>}
                    <img alt="start-img" src={startArray[starWaifu || 0]} className={`w-10 absolute top-2 right-2 `} />

                    <div className={`w-full bg-fixed relative h-52`}>
                        <GetImageAdn genData={element?.adn || "0003000300300030030003000300030030003003000300300030030030303"} mask={damanger} />
                        <div className="absolute bottom-2 right-2 p-1 border-2 rounded-xl border-[#DB1A99] bg-[#F0C1DC]">{durability.now || 0}/{durability.complete || 50}</div>
                    </div>
                    <div className={`flex justify-center items-center flex-col relative bg-backgroundOp py-1 rounded-b-lg  ${damanger ? "h-15" : !inventory ? "h-15" : "h-12"}`}>
                        <p className=" text-sm flex flex-row justify-center items-center"> <strong> {waifuPower}  WP </strong>{waifuIcon && <img src={waifuIcon} alt="material" className="w-8 p-1 " />} </p>
                        {/* {sickTime?.secondsLeft > 43200 && <button onClick={handleClickButton} className={'rounded-lg text-sm bg-[#AA2E74] text-white p-1 px-2'}>   <strong> Heal </strong>  </button>} */}
                        <button className="rounded-full bg-darkPink p-1 pl-2 font-semibold flex items-center" onClick={handleClickButton}>
                            <div className="text-white mr-1">Heal</div>
                            <div className="rounded-full px-2 bg-white text-darkPink flex items-center">
                                <span>{getPricePeach()}</span>
                                <img src={peachIcon} className="h-4 ml-1" />
                            </div>
                        </button>
                    </div>
                </div>
            }
        </>

    )
}

export default WaifuCard